import React, { useState, useRef } from 'react';
import classnames from 'classnames';
import { useRouting } from 'models/routing';
import { useLanguage } from 'models/i18n';
import { changeLng } from 'util/i18n';
import GlobeIcon from 'images/common/globe.inline.svg';
import { useAuth } from 'models/auth/common';
import { useOnclickOutside } from 'util/hook/useOnclickOutside';
import Icon from '../Icon';
import styles from './index.css';

interface ButtonLanguageProperty {
	className?: string;
	whiteText?: boolean;
}

const ButtonLanguage: React.FC<ButtonLanguageProperty> = ({ className, whiteText }) => {
	const [
		{
			i18n: { lan: prevLan },
		},
	] = useLanguage();

	const [{ pathname, search }, { historyPush }] = useRouting();
	const [, { logoutWithoutRedirect }] = useAuth();
	const [isOpen, setIsOpen] = useState(false);
	const menuRef = useRef(null);

	useOnclickOutside(menuRef, () => setIsOpen(false));

	const languages = [
		{ code: 'zh', label: 'ZH' },
		{ code: 'en', label: 'EN' },
		{ code: 'jp', label: 'JP' },
	];

	const onClickHandler = (nextLan: string) => () => {
		const regex = new RegExp(`/${prevLan}`, 'g');
		const pathnameWithoutLan = pathname.replace(regex, '');
		if (nextLan === 'en' || nextLan === 'jp') {
			logoutWithoutRedirect();
		}
		changeLng(nextLan);
		historyPush({ pathname: pathnameWithoutLan, lan: nextLan, search });
		window.location.reload();
		setIsOpen(false);
	};

	const currentLanguage = languages.find(lang => lang.code === prevLan);

	return (
		<div
			className={classnames(styles.buttonLanguage, className)}
			ref={menuRef}
			onMouseEnter={() => setIsOpen(true)}
			onMouseLeave={() => setIsOpen(false)}
		>
			<button className={classnames(styles.current, { [styles.white]: whiteText })} type="button">
				<Icon className={styles.icon} src={GlobeIcon} />
				<span>{currentLanguage?.label}</span>
			</button>
			<div className={styles.languagesBg}>
				{languages.map(lang => (
					<button
						key={lang.code}
						onClick={onClickHandler(lang.code)}
						className={classnames(styles.language, {
							[styles.active]: lang.code === prevLan,
						})}
						type="button"
					>
						<Icon className={styles.icon} src={GlobeIcon} />
						<span>{lang.label}</span>
					</button>
				))}
			</div>
		</div>
	);
};

export default ButtonLanguage;
